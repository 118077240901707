$(document).ready(function () {
  let windowWidth = setWindowWidth();
  let saveButtonPlace = "up";
  let downButtonsPosition = "static";
  let lastScrollTop = 0;
  let delta = 20;
  const buttonsDown = $("#buttons_down");
  const summary = $(".summary");

  if (window.location.pathname.includes("/payment")) {
    if (windowWidth <= 850) {
      // changeRowSpan($('#pay-status'), 1);
      // changeColSpan($('.span-headers-2'), 1);
    } else if (windowWidth <= 1103) {
      changeColSpan($(".span-headers"), 1);
      changeColSpan($(".span-headers-2"), 2);
      changeColSpan($(".span-headers-4"), 2);
    }
  }

  if (
    windowWidth <= 850 &&
    window.location.pathname.includes("/configurator")
  ) {
    moveSaveButton("down");
    downButtonsPosition = "fixed";
  } else if (
    windowWidth <= 850 &&
    !window.location.pathname.includes("/configurator")
  ) {
    buttonsDown.css("position", "static");
    buttonsDown.css("background-color", "white");
  }

  $(document).scroll(function () {
    let elArray = [buttonsDown, summary];
    if (
      windowWidth <= 850 &&
      window.location.pathname.includes("/configurator")
    ) {
      const scrollPosition = window.scrollY;
      const footerOffset = document.getElementById("app_footer").offsetTop;

      let nowScrollTop = $(this).scrollTop();

      if (Math.abs(lastScrollTop - scrollPosition) >= delta) {
        if (
          scrollPosition + window.innerHeight >= footerOffset &&
          !(downButtonsPosition == "static")
        ) {
          for (let el of elArray) {
            if (el) {
              el.css("position", "static");
            }
          }
          downButtonsPosition = "static";
        } else if (
          scrollPosition + window.innerHeight < footerOffset &&
          !(downButtonsPosition == "fixed")
        ) {
          for (let el of elArray) {
            if (el) {
              el.css("position", "fixed");
            }
          }
          downButtonsPosition = "fixed";
        }
      }
      lastScrollTop = scrollPosition;
    } else {
      for (let el of elArray) {
        if (el) {
          el.css("position", "static");
        }
      }
    }
  });

  window.onresize = function () {
    windowWidth = setWindowWidth();

    if (windowWidth <= 850) {
      moveSaveButton("down");
      setTooltipsterNarrow();
    } else {
      downButtonsPosition = "static";
      moveSaveButton("up");
      setTooltipsterWide();
    }

    if (windowWidth <= 1103 && window.location.pathname.includes("/payment")) {
      changeColSpan($(".span-headers"), 1);
      changeColSpan($(".span-headers-2"), 2);
      changeColSpan($(".span-headers-4"), 2);
    } else {
      changeColSpan($(".span-headers"), 2);
      changeColSpan($(".span-headers-2"), 3);
      changeColSpan($(".span-headers-4"), 4);
    }
  };

  function setWindowWidth() {
    return window.innerWidth;
  }

  // Pending: Review on which pages the Save Button exists add additional conditionto the line 17 nd 36
  function moveSaveButton(buttonPlace) {
    if (buttonPlace == saveButtonPlace) {
      saveButtonPlace = saveButtonPlace;
    } else if (buttonPlace == "down") {
      let elToAttach = $("#save-div").detach();
      elToAttach.appendTo("#buttons_down .row");
      saveButtonPlace = "down";
    } else {
      let elToAttach = $("#save-div").detach();
      elToAttach.appendTo(".form-config-top-nav");
      saveButtonPlace = "up";
    }
  }

  function setTooltipsterNarrow() {
    $("#help_icon").tooltipster("option", "trigger", "click");
    $(".tooltipster-question").tooltipster("option", "trigger", "click");
  }

  function setTooltipsterWide() {
    $("#help_icon").tooltipster("option", "trigger", "hover");
    $(".tooltipster-question").tooltipster("option", "trigger", "hover");
  }

  function changeColSpan(e, spanNumvb) {
    for (let col of e) {
      col.colSpan = spanNumvb;
    }
  }
});
