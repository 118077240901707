$(document).ready(function () {
  JQsetMenuVisibility = function (state) {
    switch (state) {
      case 0:
        break;
      case 1:
        $("#top_menu").show();
        break;
      case -1:
        $("#top_menu").hide();
        break;
    }
  };

  JQcloseMenu = function () {
    var visible = $("#top_menu:visible").length > 0;
    if (visible) {
      JQsetMenuVisibility(-1);
    }
  };

  $("#menu_button").on("mouseenter", function (evt) {
    var type = evt.type;
    var visible = $("#top_menu:visible").length > 0;
    JQsetMenuVisibility(1);
  });

  $("#menu_button").on("click", function (evt) {
    var visible = $("#top_menu:visible").length > 0;
    if (visible) {
      JQsetMenuVisibility(-1);
    } else {
      JQsetMenuVisibility(1);
    }
  });

  $("main").mouseenter(JQcloseMenu);
  $("#app_breadcrumb").mouseenter(JQcloseMenu);
  $("#p-slo").mouseenter(JQcloseMenu);
  $("#p-logo").mouseenter(JQcloseMenu);

  $("form#login div.button_login_submit").click(function () {
    $(this).parent("form").submit();
  });

  $(window).scroll(function () {
    if ($("#top_menu:visible").length > 0) {
      JQsetMenuVisibility(-1);
    }
  });
});
