/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');
//
//// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require("jquery");

require("jquery-ui/ui/widgets/dialog");

//
//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
//
global.$ = global.jQuery = $;

//var $ = require('jquery');
window.$ = $;
window.jQuery = $;

require("./responsive");
require("./menu");
require("../css/app.scss");
require("../css/payment_stepper.css");
require("../css/normalize.css");
require("../css/style_admin.css");

require("../css/app.scss");
require("bootstrap");
require("tooltipster");
require("jquery-number");
require("jquery-validation");
require("jquery-validation/dist/additional-methods");
require("jquery-easy-loading");
require("chart.js");
require("chart.js/dist/Chart.js");
require("chart.js/dist/Chart.min.js");
require("chart.js/dist/Chart.bundle.js");
require("chart.js/dist/Chart.bundle.min.js");
